@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Extralight.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-ExtralightItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-LightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Medium.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-MediumItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-RegularItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Semibold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-SemiboldItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Super.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-SuperItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("../fonts/Graphik-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}